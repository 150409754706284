import { useEffect } from "react";
import ModelFour from "../Models/ModelFour/ModelFour";
import ModelOne from "../Models/ModelOne/ModelOne";
import ModelThree from "../Models/ModelThree/ModelThree";
import ModelTwo from "../Models/ModelTwo/ModelTwo";
import "./ModelContent.css";

export default function ModelContent(props) {
  let {
    selectedModel,
    setSelectedModel,
    setModelOne,
    modelOne,
    setSelectedImageButton,
    setSelectModul,
    setOnLoading,
  } = props;
  // useEffect(() => {
  //   setOnLoading((prev) => prev + 1);
  // }, []);
  return (
    <>
      {selectedModel == "none" && (
        <div className="ModelContent">
          <div className="navbar">
            <img src="./images/main/navbar.png" alt="" />
          </div>
          <div className="models">
            <div
              className="Model1"
              onClick={() => {
                setSelectedModel("model1");
              }}
            >
              <span>Yotoqxona</span>
              <div className="lineImage"></div>
              <img src="/images/bg/modelBacground1.jpg" alt="modelBacground1" />
            </div>
            <div
              className="Model2"
              onClick={() => {
                setSelectedModel("model2");
              }}
            >
              <span>Ofis</span>
              <div className="lineImage"></div>
              <img src="/images/bg/modelBacground2.jpg" alt="modelBacground2" />
            </div>
            <div
              className="Model3"
              onClick={() => {
                setSelectedModel("model3");
              }}
            >
              <span>Bolalar yotoqxonasi</span>
              <div className="lineImage"></div>
              <img src="/images/bg/modelBacground3.jpg" alt="modelBacground3" />
            </div>
            <div
              className="Model4"
              onClick={() => {
                setSelectedModel("model4");
              }}
            >
              <span>Oshxona</span>
              <div className="lineImage"></div>
              <img src="/images/bg/modelBacground4.jpg" alt="modelBacground4" />
            </div>
          </div>
        </div>
      )}
      {selectedModel == "model1" && (
        <ModelOne
          setModelOne={setModelOne}
          modelOne={modelOne}
          setSelectedImageButton={setSelectedImageButton}
          setSelectModul={setSelectModul}
          setOnLoading={setOnLoading}
        />
      )}
      {selectedModel == "model2" && (
        <ModelTwo
          setModelOne={setModelOne}
          modelOne={modelOne}
          setSelectedImageButton={setSelectedImageButton}
          setSelectModul={setSelectModul}
          setOnLoading={setOnLoading}
        />
      )}
      {selectedModel == "model3" && (
        <ModelThree
          setModelOne={setModelOne}
          modelOne={modelOne}
          setSelectedImageButton={setSelectedImageButton}
          setSelectModul={setSelectModul}
          setOnLoading={setOnLoading}
        />
      )}
      {selectedModel == "model4" && (
        <ModelFour
          setModelOne={setModelOne}
          modelOne={modelOne}
          setSelectedImageButton={setSelectedImageButton}
          setSelectModul={setSelectModul}
          setOnLoading={setOnLoading}
        />
      )}
    </>
  );
}

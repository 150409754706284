import "./SelectCompanyMenu.css";
export default function SelectCompanyMenu(props) {
  let { setCurrentSelectPageRight, setSelectCompany, Cid } = props;
  return (
    <div
      className="SelectCompanyMenu"
      onClick={() => {
        setCurrentSelectPageRight("data");
        setSelectCompany(Cid);
      }}
    >
      <span>{props.children}</span>
    </div>
  );
}

import * as THREE from "three";

let setImage = (model, img2) => {
  let textureload = new THREE.TextureLoader();
  // textureload.magFilter = THREE.NearestFilter;
  let isGroup = model.isGroup;
  let img = textureload.load(img2);
  img.wrapS = THREE.RepeatWrapping;
  img.wrapT = THREE.RepeatWrapping;
  img.colorSpace = THREE.SRGBColorSpace;
  // img.magFilter = THREE.NearestMipmapNearestFilter;

  if (isGroup) {
    let arr = model.children;
    arr.map((a, b) => {
      // console.log(a.material);
      a.material.map = img;
    });
  } else {
    // console.log((model.material.roughness = 1));
    model.material.map = img;
  }
};

let setColor = (model, color) => {
  let isGroup = model.isGroup;
  if (isGroup) {
    let arr = model.children;
    arr.map((a, b) => {
      a.material.color.set(color);
    });
  } else {
    model.material.color.set(color);
  }
};

export { setImage, setColor };

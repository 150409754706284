import { useEffect, useState } from "react";
import LeftPonel from "../Components/LeftPonel/LeftPonel";
import RightPonel from "../Components/RightPonel/RightPonel";
import "./Main.css";
import DownContent from "../Components/DownContent/DownContent";
import ModelContent from "../Components/ModelContent/ModelContent";
import LoadingPage from "../Components/LoadingPage/LoadingPage";
export default function Main(props) {
  let [modelOne, setModelOne] = useState();
  let [selectModul, setSelectModul] = useState(2);
  let [onLoading, setOnLoading] = useState(0);
  let [selectedImageButton, setSelectedImageButton] = useState("");

  // ! right panel sozlamalari.
  let [currentSelectPageRight, setCurrentSelectPageRight] = useState("type");
  let [openRightMenu, setOpenRightMenu] = useState(false);

  // ! get textures
  let [texturType, setTexturType] = useState([]); // turi (mdf stol)
  let [companys, setCompanyes] = useState([]); // firmasi
  let [products, setProducts] = useState([]);

  let [selectCompany, setSelectCompany] = useState("");
  let [selectType, setSelectType] = useState("");

  let [texturId, setTextueId] = useState(""); // ID
  let [texturSee, setTexturSee] = useState(""); // ko'rinishi (matviy/glyans)
  let [allData, setAllData] = useState({}); // Malumotlar bazasi. (Fake)
  let [allDataLoading, setAllDataLoading] = useState(false); //Malumotlar yuklash xolati  (False=> tugallanmaganm True=>Tugallangan)

  // ! Selected model
  let [selectedModel, setSelectedModel] = useState("none"); // none
  let {} = props;
  // return <DownContent />;

  return (
    <div className="MainPage">
      {selectedModel != "none" && onLoading != "none" && (
        <LoadingPage onLoading={onLoading} />
      )}
      <DownContent
        selectCompany={selectCompany}
        selectType={selectType}
        companys={companys}
        setCompanyes={setCompanyes}
        texturType={texturType}
        setTexturType={setTexturType}
        setProducts={setProducts}
        // texturId={texturId}
        // setTextueId={setTextueId}
        // texturSee={texturSee}
        // setTexturSee={setTexturSee}
        // allData={allData}
        // setAllData={setAllData}
        // allDataLoading={allDataLoading}
        // setAllDataLoading={setAllDataLoading}
      />
      {/* <LeftPonel /> */}
      <ModelContent
        setModelOne={setModelOne}
        modelOne={modelOne}
        setSelectModul={setSelectModul}
        setOnLoading={setOnLoading}
        setSelectedImageButton={setSelectedImageButton}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
      />

      {selectedModel != "none" && (
        <RightPonel
          modelOne={modelOne}
          selectModul={selectModul}
          selectedImageButton={selectedImageButton}
          setSelectedImageButton={setSelectedImageButton}
          allData={allData}
          companys={companys}
          setCompanyes={setCompanyes}
          texturType={texturType}
          setTexturType={setTexturType}
          allDataLoading={allDataLoading}
          currentSelectPageRight={currentSelectPageRight}
          setCurrentSelectPageRight={setCurrentSelectPageRight}
          openRightMenu={openRightMenu}
          setOpenRightMenu={setOpenRightMenu}
          setSelectCompany={setSelectCompany}
          setSelectType={setSelectType}
          products={products}
        />
      )}
    </div>
  );
}

import { setImage } from "../Functions";
import SelectModeImage from "../SelectModeImage/SelectModeImage";
import "./SelectDatesMenu.css";

export default function SelectDatesMenu(props) {
  let {
    modelOne,
    selectModul,
    selectedImageButton,
    setSelectedImageButton,
    i,
  } = props;
  let isYes = [
    ,
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "10010",
    "10011",
    "10012",
    "10013",
    "10014",
    "10015",
    "10017",
    "10018",
    "10019",
    "10020",
    "10027",
    "10083",
    "10084",
    "10085",
    "10101",
    "10302",
    "10557",
    "10562",
    "10564",
    "10567",
    "10569",
    "10571",
    "10573",
    "10575",
    "10577",
    "10638",
    "10665",
    "10666",
    "10798",
    "10805",
    "10994",
    "11",
    "11000",
    "11026",
    "11028",
    "11072",
    "11073",
    "11074",
    "11075",
    "11076",
    "11077",
    "11078",
    "11106",
    "11107",
    "11192",
    "11194",
    "11232",
    "11251",
    "11332",
    "11333",
    "11334",
    "11435",
    "11449",
    "11450",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "4895",
    "4896",
    "4897",
    "4898",
    "4899",
    "4901",
    "4902",
    "4905",
    "4907",
    "4908",
    "4909",
    "4910",
    "4989",
    "4991",
    "5063",
    "5064",
    "5065",
    "5068",
    "5069",
    "5070",
    "5073",
    "5075",
    "5077",
    "5080",
    "5085",
    "5090",
    "5091",
    "5094",
    "5096",
    "5098",
    "5109",
    "5110",
    "5116",
    "5117",
    "5119",
    "5127",
    "5128",
    "5131",
    "5132",
    "5135",
    "5136",
    "5138",
    "5139",
    "5140",
    "5141",
    "5142",
    "5144",
    "5146",
    "5147",
    "5148",
    "5149",
    "5152",
    "5154",
    "5158",
    "5159",
    "5161",
    "5162",
    "5164",
    "5168",
    "5175",
    "5181",
    "5187",
    "5188",
    "5189",
    "5190",
    "5191",
    "5198",
    "5200",
    "5201",
    "5202",
    "5203",
    "5206",
    "5225",
    "5227",
    "5229",
    "5236",
    "5240",
    "5241",
    "5242",
    "5248",
    "5249",
    "5250",
    "5253",
    "5255",
    "5258",
    "5261",
    "5263",
    "5264",
    "5268",
    "5270",
    "5274",
    "5278",
    "5292",
    "5302",
    "5334",
    "5339",
    "5340",
    "5342",
    "5343",
    "5344",
    "5345",
    "5346",
    "5347",
    "5349",
    "5350",
    "5352",
    "5353",
    "5356",
    "5357",
    "5358",
    "5361",
    "5364",
    "5366",
    "5368",
    "5369",
    "5371",
    "5372",
    "5374",
    "5375",
    "5377",
    "5379",
    "5380",
    "5381",
    "5382",
    "5383",
    "5385",
    "5387",
    "5388",
    "5390",
    "5391",
    "5395",
    "5396",
    "5397",
    "5413",
    "6807",
    "6820",
    "6831",
    "6834",
    "6835",
    "6839",
    "6840",
    "6843",
    "6846",
    "6847",
    "6853",
    "6857",
    "6865",
    "6877",
    "6879",
    "6881",
    "6882",
    "6884",
    "6886",
    "8113",
    "8118",
    "8121",
    "8125",
    "8332",
    "8348",
    "8349",
    "8351",
    "8355",
    "8421",
    "8422",
    "8428",
    "8465",
    "8500",
    "8578",
    "8580",
    "8581",
    "8582",
    "8637",
    "8640",
    "8652",
    "8653",
    "8715",
    "8732",
    "8771",
    "8893",
    "8896",
    "9002",
    "9079",
    "9080",
    "9081",
    "9082",
    "9119",
    "9191",
    "9212",
    "9213",
    "9216",
    "9218",
    "9220",
    "9223",
    "9227",
    "9293",
    "9339",
    "9340",
    "9341",
    "9373",
    "9374",
    "9378",
    "9389",
    "9442",
    "9455",
    "9658",
    "9659",
    "9660",
    "9662",
    "9663",
    "9676",
    "9679",
    "9714",
    "9723",
    "9743",
    "9744",
    "9749",
    "9770",
    "9771",
    "9772",
    "9773",
    "9774",
    "9775",
    "9839",
    "9856",
    "9857",
    "9858",
    "9859",
    "9876",
    "9890",
    "9891",
    "9893",
    "9894",
    "9895",
  ];

  // console.log();

  return (
    isYes.includes(`${props.children.code}`) && (
      <div
        key={i}
        className="SelectDatesMenu"
        onClick={() => {
          setImage(
            modelOne[selectModul],
            `./images/textures/${props.children.code}.jpg`
          );
        }}
        style={{
          background: `url("images/textures/${props.children.code}.jpg") center center / cover`,
        }}
      ></div>
    )
  );
  // <SelectModeImage
  //   setSelectedImageButton={setSelectedImageButton}
  //   selectedImageButton={selectedImageButton}
  //   // setImage={setImage}
  //   modelOne={modelOne}
  //   selectModul={selectModul}
  //   // textureload={textureload}
  //   image={props.children.productTextur}
  // />
}

import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import {
  BloomPass,
  FilmPass,
  GLTFLoader,
  OutputPass,
  RectAreaLightHelper,
  RenderPass,
} from "three/examples/jsm/Addons.js";
import { OrbitControls } from "three/examples/jsm/Addons.js";
import { setColor } from "../../../Functions/Functions";

export default function ModelOne(props) {
  const useRefCon = useRef(null);
  let {
    setModelOne,
    modelOne,
    setSelectModul,
    setOnLoading,
    setSelectedImageButton,
  } = props;
  function animate() {
    requestAnimationFrame(animate);
    render.render(scene, camera);
  }

  // ! render
  const render = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    powerPreference: "low-power",
    stencil: true,
  });
  render.toneMapping = THREE.ReinhardToneMapping;
  render.toneMappingExposure = 1.5;
  render.setSize(window.innerWidth, window.innerHeight);

  // ! Scene
  const scene = new THREE.Scene();
  // ! Camera
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  // camera.rotation.y = 90;
  // camera.rotation.z = 90;
  // camera.rotation.set((-90 * Math.PI) / 180, 0, 10);

  // camera.rotation.set(0, 0, 0);

  // ! Controls
  const controls = new OrbitControls(camera, render.domElement);
  // controls.target = new THREE.Vector3(1, 1, 1);
  controls.update();

  camera.position.x = -10; //chp ong
  camera.position.y = 20; //baland pas
  camera.position.z = 100; //uzoq yaqn
  // camera.rotation.y = 40;
  // camera.rotation.x = Math.PI * 1.0004;

  // ! Lightings
  const ambientLight = new THREE.AmbientLight(0xffffff, 3);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(0, 10, 0);
  scene.add(directionalLight);

  // ! Edit texture
  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load("/model/modelOne/modelOne.glb", (gltf) => {
      const mesh = gltf.scene;
      scene.add(mesh);
      let modelNumber1 = mesh.children[4]; //TUMBOCHKA
      let modelNumber2 = mesh.children[10]; //KARAVAT
      let modelNumber3 = mesh.children[7]; //SHKAF
      let modelNumber4 = mesh.children[8]; //STOL

      setModelOne([
        modelNumber1, //general_01 shkaf
        modelNumber2, //general_02 mini shakf
        modelNumber3, //general_03 stol rakvina
        modelNumber4, //general_04 devol rakvina
      ]);

      const mouse = new THREE.Vector2();
      const raycaster2 = new THREE.Raycaster();
      document.body.addEventListener("click", (e) => {
        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
        raycaster2.setFromCamera(mouse, camera);
        const model0Click = raycaster2.intersectObject(mesh.children[2]); //TUMBOCHKA
        const model1Click = raycaster2.intersectObject(mesh.children[3]); //KARAVAT
        const model2Click = raycaster2.intersectObject(mesh.children[1]); //SHKAF
        const model3Click = raycaster2.intersectObject(mesh.children[6]); //STOL

        if (
          model0Click.length > 0 ||
          model1Click.length > 0 ||
          model2Click.length > 0 ||
          model3Click.length > 0
        ) {
          setColor(mesh.children[2], "white");
          setColor(mesh.children[3], "white");
          setColor(mesh.children[1], "white");
          setColor(mesh.children[6], "white");

          if (model0Click.length > 0) {
            setSelectModul(0);
            setSelectedImageButton("");
            setColor(mesh.children[2], "#f3a0b4");
          } else if (model1Click.length > 0) {
            setSelectModul(1);
            setSelectedImageButton("");
            setColor(mesh.children[3], "#f3a0b4");
          } else if (model2Click.length > 0) {
            setSelectModul(2);
            setSelectedImageButton("");
            setColor(mesh.children[1], "#f3a0b4");
          } else if (model3Click.length > 0) {
            setSelectModul(3);
            setSelectedImageButton("");
            setColor(mesh.children[6], "#f3a0b4");
          }
        }
      });
      setOnLoading((prev) => prev + 1);
      setTimeout(() => {
        setOnLoading("none");
      }, 2000);
    });
  }, []);

  // ! rendering
  useEffect(() => {
    if (useRefCon.current) {
      useRefCon.current.appendChild(render.domElement);
    }
    animate();
  }, [useRefCon]);

  return (
    <div>
      <div className="model" ref={useRefCon}></div>
    </div>
  );
}

import "./SelectTypesMenu.css";
export default function SelectTypesMenu(props) {
  let { setCurrentSelectPageRight, setSelectType, Cid } = props;
  return (
    <div
      className="SelectTypesMenu"
      onClick={() => {
        setCurrentSelectPageRight("company");
        setSelectType(Cid);
      }}
    >
      <span>{props.children}</span>
    </div>
  );
}

import { useEffect } from "react";
import "./DownContent.css";
import { encode } from "base-64";
import { PropertyNode } from "three/webgpu";

export default function DownContent(props) {
  useEffect(() => {
    /*
    let url = "http://89.104.102.124:8080/Obmen/hs/front/product";
    let username = "Админстратор";
    let password = "2275157abc";

    fetch(url, {
      method: "GET",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + encode(`${username}:${password}`),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
      });
      */
    /**
     *
     * category
     *  Stoleshnitsa=> 4b44a6ac-91e3-11ef-99d4-b4a9fc2034ab
     *  Akril=> 4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab
     *  Ldsp=> 4b44a6a9-91e3-11ef-99d4-b4a9fc2034ab
     *  LMDF=> 609ae3a5-b211-11ef-8b28-50ebf63d3c05
     *
     *
     *
     */
  }, []);
  let typeProduct = [
    { Cname: "Stoleshnitsa", Cid: "4b44a6ac-91e3-11ef-99d4-b4a9fc2034ab" },
    { Cname: "Akril", Cid: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab" },
    { Cname: "Ldsp", Cid: "4b44a6a9-91e3-11ef-99d4-b4a9fc2034ab" },
    { Cname: "LMDF", Cid: "609ae3a5-b211-11ef-8b28-50ebf63d3c05" },
  ];

  let comProduct = [
    {
      id: "5e00ed89-91f1-11ef-8b26-50ebf63d3c05",
      name: "YHAOU",
      code: "000000037",
    },
    {
      id: "1adcf348-94d8-11ef-8b26-50ebf63d3c05",
      name: "XSOFT",
      code: "000000042",
    },
    {
      id: "ad8faf8b-94e7-11ef-8b26-50ebf63d3c05",
      name: "Aventus",
      code: "000000043",
    },
    {
      id: "6eb80c41-9672-11ef-8b26-50ebf63d3c05",
      name: "Yuwei",
      code: "000000044",
    },
    {
      id: "a67068c8-9673-11ef-8b26-50ebf63d3c05",
      name: "Freud",
      code: "000000045",
    },
    {
      id: "3830905f-9699-11ef-8b26-50ebf63d3c05",
      name: "Epica",
      code: "000000046",
    },
    {
      id: "0eb4ee7d-981a-11ef-8b26-50ebf63d3c05",
      name: "Bemas",
      code: "000000047",
    },
    {
      id: "6049540e-981a-11ef-8b26-50ebf63d3c05",
      name: "Mepa",
      code: "000000048",
    },
    {
      id: "d246160e-981a-11ef-8b26-50ebf63d3c05",
      name: "Lucido",
      code: "000000049",
    },
    {
      id: "cd07bac0-981b-11ef-8b26-50ebf63d3c05",
      name: "Leon",
      code: "000000050",
    },
    {
      id: "136ff5e7-98cf-11ef-8b26-50ebf63d3c05",
      name: "Evson",
      code: "000000051",
    },
    {
      id: "8b33d571-9aa8-11ef-8b26-50ebf63d3c05",
      name: "Zamok",
      code: "000000052",
    },
    {
      id: "43f042d5-9ac8-11ef-8b26-50ebf63d3c05",
      name: "Eurotex",
      code: "000000053",
    },
    {
      id: "e1fe7b17-9ac8-11ef-8b26-50ebf63d3c05",
      name: "C.MIX",
      code: "000000054",
    },
    {
      id: "32bf982c-9b41-11ef-8b26-50ebf63d3c05",
      name: "Gaya",
      code: "000000056",
    },
    {
      id: "16f8f59d-9bfd-11ef-8b26-50ebf63d3c05",
      name: "Artavil gold",
      code: "000000057",
    },
    {
      id: "9b53ee12-9c15-11ef-8b26-50ebf63d3c05",
      name: "ASD",
      code: "000000058",
    },
    {
      id: "9c415454-9d8a-11ef-8b26-50ebf63d3c05",
      name: "Evogloss",
      code: "000000059",
    },
    {
      id: "18b12c7f-9d9c-11ef-8b26-50ebf63d3c05",
      name: "Sar",
      code: "000000060",
    },
    {
      id: "baeac939-9db4-11ef-8b26-50ebf63d3c05",
      name: "Deko",
      code: "000000061",
    },
    {
      id: "9ae88d7b-9e5a-11ef-8b26-50ebf63d3c05",
      name: "Tytan",
      code: "000000062",
    },
    {
      id: "004ff506-a10d-11ef-8b27-50ebf63d3c05",
      name: "ASO",
      code: "000000063",
    },
    {
      id: "f6b69466-a112-11ef-8b27-50ebf63d3c05",
      name: "Milate",
      code: "000000064",
    },
    {
      id: "9dd51049-a114-11ef-8b27-50ebf63d3c05",
      name: "XSOFT",
      code: "000000065",
    },
    {
      id: "2aeb8bcb-a115-11ef-8b27-50ebf63d3c05",
      name: "Yakle",
      code: "000000066",
    },
    {
      id: "20685aca-a11a-11ef-8b27-50ebf63d3c05",
      name: "MRIZO",
      code: "000000067",
    },
    {
      id: "c3e1cdab-a5c3-11ef-8b28-50ebf63d3c05",
      name: "Erior",
      code: "000000068",
    },
    {
      id: "a87149ee-a5cd-11ef-8b28-50ebf63d3c05",
      name: "Mob",
      code: "000000070",
    },
    {
      id: "f7b9d3b6-a640-11ef-8b28-50ebf63d3c05",
      name: "Ideal",
      code: "000000071",
    },
    {
      id: "ad87f27b-a641-11ef-8b28-50ebf63d3c05",
      name: "Tesay",
      code: "000000072",
    },
    {
      id: "23f1c88d-a67c-11ef-8b28-50ebf63d3c05",
      name: "Fixal",
      code: "000000073",
    },
    {
      id: "495d9872-a67c-11ef-8b28-50ebf63d3c05",
      name: "Polisan",
      code: "000000074",
    },
    {
      id: "e989066e-a67d-11ef-8b28-50ebf63d3c05",
      name: "Rexon",
      code: "000000075",
    },
    {
      id: "72c9eee9-a67e-11ef-8b28-50ebf63d3c05",
      name: "Sibax",
      code: "000000076",
    },
    {
      id: "e4de477f-a680-11ef-8b28-50ebf63d3c05",
      name: "HOHLER",
      code: "000000077",
    },
    {
      id: "05c1045e-a69a-11ef-8b28-50ebf63d3c05",
      name: "Wasserman",
      code: "000000078",
    },
    {
      id: "4705767a-a6fd-11ef-8b28-50ebf63d3c05",
      name: "Camel",
      code: "000000079",
    },
    {
      id: "9525e0fb-a72a-11ef-8b28-50ebf63d3c05",
      name: "WQ",
      code: "000000080",
    },
    {
      id: "cf6aa7db-a730-11ef-8b28-50ebf63d3c05",
      name: "Abrasoft",
      code: "000000081",
    },
    {
      id: "e1d56e15-a730-11ef-8b28-50ebf63d3c05",
      name: "Diamond",
      code: "000000082",
    },
    {
      id: "23d2f6d3-a732-11ef-8b28-50ebf63d3c05",
      name: "Gench",
      code: "000000083",
    },
    {
      id: "a9cb8a66-a732-11ef-8b28-50ebf63d3c05",
      name: "Dayson",
      code: "000000084",
    },
    {
      id: "ec815e39-a829-11ef-8b28-50ebf63d3c05",
      name: "META",
      code: "000000085",
    },
    {
      id: "e087410c-a8e2-11ef-8b28-50ebf63d3c05",
      name: "STW",
      code: "000000086",
    },
    {
      id: "0bdb3d4c-abeb-11ef-8b28-50ebf63d3c05",
      name: "Bear",
      code: "000000087",
    },
    {
      id: "63c04770-bd07-11ef-8b28-50ebf63d3c05",
      name: "HOMAT",
      code: "000000089",
    },
    {
      id: "197390cd-daf5-11ef-8b2a-50ebf63d3c05",
      name: "Evert",
      code: "000000090",
    },
    {
      id: "a3a5f0b3-db1e-11ef-8b2a-50ebf63d3c05",
      name: "NISKO",
      code: "000000091",
    },
    {
      id: "c31593be-a6fc-11ef-974d-ec63d745503c",
      name: "Sinkstar",
      code: "000000085",
    },
    {
      id: "1af44b25-a886-11ef-974d-ec63d745503c",
      name: "Zhiwei",
      code: "000000086",
    },
    {
      id: "1af44b26-a886-11ef-974d-ec63d745503c",
      name: "KOF",
      code: "000000087",
    },
    {
      id: "78e2033a-bc29-11ef-974d-ec63d745503c",
      name: "Plum",
      code: "000000088",
    },
    {
      id: "55da4f11-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Alkan",
      code: "000000001",
    },
    {
      id: "55da4f12-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Starax",
      code: "000000002",
    },
    {
      id: "55da4f13-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Furnimax",
      code: "000000003",
    },
    {
      id: "55da4f14-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Eman",
      code: "000000004",
    },
    {
      id: "55da4f15-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Kronoshpan",
      code: "000000005",
    },
    {
      id: "55da4f16-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Aousite",
      code: "000000006",
    },
    {
      id: "55da4f17-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Baifeibu",
      code: "000000007",
    },
    {
      id: "55da4f18-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Samett",
      code: "000000008",
    },
    {
      id: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Agt",
      code: "000000009",
    },
    {
      id: "55da4f1a-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Ms",
      code: "000000010",
    },
    {
      id: "55da4f1b-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Maptun",
      code: "000000011",
    },
    {
      id: "55da4f1c-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Dts",
      code: "000000012",
    },
    {
      id: "55da4f1d-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Epa",
      code: "000000013",
    },
    {
      id: "55da4f1e-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Akfix",
      code: "000000014",
    },
    {
      id: "55da4f1f-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Soudal",
      code: "000000015",
    },
    {
      id: "55da4f20-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Victory",
      code: "000000016",
    },
    {
      id: "55da4f21-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Tece",
      code: "000000017",
    },
    {
      id: "55da4f22-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Starvud",
      code: "000000018",
    },
    {
      id: "55da4f23-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Fsl",
      code: "000000019",
    },
    {
      id: "55da4f24-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Safia",
      code: "000000020",
    },
    {
      id: "55da4f25-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Dana",
      code: "000000021",
    },
    {
      id: "55da4f26-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Kedr",
      code: "000000022",
    },
    {
      id: "55da4f27-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Soyuz",
      code: "000000023",
    },
    {
      id: "55da4f28-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Skif",
      code: "000000024",
    },
    {
      id: "55da4f29-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Sher nur",
      code: "000000025",
    },
    {
      id: "55da4f2a-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Kitay XG",
      code: "000000026",
    },
    {
      id: "55da4f2b-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Kitay WQ",
      code: "000000027",
    },
    {
      id: "55da4f2c-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Pakchoob",
      code: "000000028",
    },
    {
      id: "55da4f2d-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Emaks",
      code: "000000029",
    },
    {
      id: "55da4f2e-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Arden",
      code: "000000030",
    },
    {
      id: "55da4f2f-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Leitz",
      code: "000000031",
    },
    {
      id: "55da4f30-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Fortuna",
      code: "000000032",
    },
    {
      id: "55da4f31-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Nanxing",
      code: "000000033",
    },
    {
      id: "55da4f32-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Iba",
      code: "000000034",
    },
    {
      id: "55da4f33-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Razniy brend",
      code: "000000035",
    },
    {
      id: "55da4f34-91e7-11ef-99d4-b4a9fc2034ab",
      name: "Arkopa",
      code: "000000036",
    },
    {
      id: "d4fb4141-9281-11ef-99d4-b4a9fc2034ab",
      name: "Aventus",
      code: "000000038",
    },
    {
      id: "d4fb4146-9281-11ef-99d4-b4a9fc2034ab",
      name: "Yuye",
      code: "000000039",
    },
    {
      id: "d4fb414b-9281-11ef-99d4-b4a9fc2034ab",
      name: "EvroFix",
      code: "000000041",
    },
    {
      id: "708051b6-9acc-11ef-99d6-b4a9fc2034ab",
      name: "Kashtan",
      code: "000000055",
    },
  ];

  let products = [
    {
      id: "189b27a0-c35f-11ed-8087-fcf8ae709418",
      name: "Akril AGT 6007 HG EFES BEYAZ 18mm",
      code: 4903,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 12,
    },
    {
      id: "189b279f-c35f-11ed-8087-fcf8ae709418",
      name: "734, Акрил AGT 2800*1220*18 Soft touch white 734",
      code: 4902,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 2,
    },
    {
      id: "189b27a6-c35f-11ed-8087-fcf8ae709418",
      name: "Akril AGT-620 HG KAHVE AGT 18mm ",
      code: 4909,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 3,
    },
    {
      id: "189b2799-c35f-11ed-8087-fcf8ae709418",
      name: "Акрил AGT 2800*1220*18 Super Matt Toros White Marble 3030",
      code: 4896,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 3,
    },
    {
      id: "189b27a2-c35f-11ed-8087-fcf8ae709418",
      name: "Akril AGT 6022 ANTHRACITE 18mm",
      code: 4905,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 5,
    },
    {
      id: "189b279c-c35f-11ed-8087-fcf8ae709418",
      name: "6021, Акрил AGT 2800*1220*18 HG Chrome Grey 6021",
      code: 4899,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 2,
    },
    {
      id: "189b279e-c35f-11ed-8087-fcf8ae709418",
      name: "732, Акрил AGT 2800*1220*18 Soft touch vison 732",
      code: 4901,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 4,
    },
    {
      id: "189b279a-c35f-11ed-8087-fcf8ae709418",
      name: "3031, Акрил AGT 2800*1220*18 Super Matt Toros Black Marble 3031",
      code: 4897,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 6,
    },
    {
      id: "189b27a1-c35f-11ed-8087-fcf8ae709418",
      name: "Akril AGT 6018 Toros White Marble  18mm",
      code: 4904,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 2,
    },
    {
      id: "189b27a7-c35f-11ed-8087-fcf8ae709418",
      name: "Akril AGT-664 HG DALGALI BEYAZ 18mm ",
      code: 4910,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 1,
    },
    {
      id: "189b2798-c35f-11ed-8087-fcf8ae709418",
      name: "3023, Акрил AGT 2800*1220*18 Dolphin Grey 3023",
      code: 4895,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 4,
    },
    {
      id: "189b279b-c35f-11ed-8087-fcf8ae709418",
      name: "6019, Акрил AGT 2800*1220*18 HG Toros Black Marble 6019",
      code: 4898,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 1,
    },
    {
      id: "189b27a4-c35f-11ed-8087-fcf8ae709418",
      name: "Akril AGT-374 BEYAZ GRI COUNTRY 18mm",
      code: 4907,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 1,
    },
    {
      id: "189b27a5-c35f-11ed-8087-fcf8ae709418",
      name: "Akril AGT-6005 HG PORTE DORE 18mm ",
      code: 4908,
      brandId: "55da4f19-91e7-11ef-99d4-b4a9fc2034ab",
      categoryId: "4b44a6ab-91e3-11ef-99d4-b4a9fc2034ab",
      remainder: 5,
    },
  ];

  let { setCompanyes, setTexturType, setProducts } = props;
  useEffect(() => {
    setCompanyes(comProduct);
    setTexturType(typeProduct);
    setProducts(products);
  }, []);
}

import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import {
  BloomPass,
  FilmPass,
  GLTFLoader,
  OutputPass,
  RectAreaLightHelper,
  RenderPass,
} from "three/examples/jsm/Addons.js";
import { OrbitControls } from "three/examples/jsm/Addons.js";
import { setColor } from "../../../Functions/Functions";

export default function ModelFour(props) {
  const useRefCon = useRef(null);
  let {
    setModelOne,
    modelOne,
    setSelectModul,
    setOnLoading,
    setSelectedImageButton,
  } = props;

  function animate() {
    requestAnimationFrame(animate);
    render.render(scene, camera);
  }

  // ! render
  const render = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    powerPreference: "low-power",
    stencil: true,
  });
  render.outputColorSpace = THREE.SRGBColorSpace;
  render.toneMapping = THREE.CineonToneMapping;
  render.toneMappingExposure = 1.5;
  render.setSize(window.innerWidth, window.innerHeight);

  // ! Scene
  const scene = new THREE.Scene();
  // ! Camera
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  camera.position.x = 10;
  camera.position.z = 10;
  camera.position.y = 10;

  // ! Controls
  const controls = new OrbitControls(camera, render.domElement);
  // controls.enableDamping = true;
  // controls.enablePan = false;
  // controls.minDistance = 5;
  // controls.maxDistance = 20;
  // controls.minPolarAngle = 0.5;
  // controls.maxPolarAngle = 1.5;
  // controls.autoRotate = false;
  // controls.enableRotate = false;
  controls.target = new THREE.Vector3(-1, 1, 2);
  controls.update();

  // ! Lightings
  const ambientLight = new THREE.AmbientLight(0xffffff, 3);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(0, 10, 0);
  scene.add(directionalLight);

  // const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
  // scene.add(hemisphereLight);

  // const pointLight = new THREE.PointLight();
  // const pointLightHelper = new THREE.PointLightHelper(pointLight);
  // pointLight.position.set(0, 10, 10);
  // pointLight.distance = 40;
  // scene.add(pointLight);
  // scene.add(pointLightHelper);

  // const color = 0xffffff;
  // const intensity = 150;
  // const spotLight = new THREE.SpotLight(color, intensity);
  // spotLight.position.set(10, 10, 10);
  // const helperSpotLight = new THREE.SpotLightHelper(spotLight);
  // scene.add(helperSpotLight);
  // scene.add(spotLight);
  // scene.add(spotLight.target);

  // const color = 0x000000;
  // const intensity = 10;
  // const width = 12;
  // const height = 12;
  // const rectAreaLight = new THREE.RectAreaLight(
  //   color,
  //   intensity,
  //   width,
  //   height
  // );
  // rectAreaLight.position.set(0, 6, 0);
  // rectAreaLight.rotation.x = THREE.MathUtils.degToRad(0);
  // rectAreaLight.rotation.y = THREE.MathUtils.degToRad(0);
  // rectAreaLight.rotation.z = THREE.MathUtils.degToRad(-180);
  // scene.add(rectAreaLight);
  // const helperRectAreaLight = new RectAreaLightHelper(rectAreaLight);
  // rectAreaLight.add(helperRectAreaLight);
  // ! Edit texture
  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load("./model/modelFour/modelFour.glb", (gltf) => {
      const mesh = gltf.scene;

      scene.add(mesh);
      let modelNumber1 = mesh.children[1]; //general_01 shkaf
      let modelNumber2 = mesh.children[2]; //general_02 mini shakf
      let modelNumber3 = mesh.children[0]; //general_03 stol rakvina
      let modelNumber4 = mesh.children[3]; //general_04 devol rakvina

      setModelOne([
        modelNumber1, //general_01 shkaf
        modelNumber2, //general_02 mini shakf
        modelNumber3, //general_03 stol rakvina
        modelNumber4, //general_04 devol rakvina
      ]);

      const mouse = new THREE.Vector2();
      const raycaster2 = new THREE.Raycaster();
      document.body.addEventListener("click", (e) => {
        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
        raycaster2.setFromCamera(mouse, camera);
        const model0Click = raycaster2.intersectObject(mesh.children[11]); //general_01 shkaf =>
        const model1Click = raycaster2.intersectObject(mesh.children[10]); //general_02 mini shakf =>
        const model2Click = raycaster2.intersectObject(mesh.children[13]); //general_03 stol rakvina =>
        const model3Click = raycaster2.intersectObject(mesh.children[9]); //general_04 devol rakvina =>

        if (
          model0Click.length > 0 ||
          model1Click.length > 0 ||
          model2Click.length > 0 ||
          model3Click.length > 0
        ) {
          setColor(mesh.children[11], "white");
          setColor(mesh.children[10], "white");
          setColor(mesh.children[13], "white");
          setColor(mesh.children[9], "white");
          if (model0Click.length > 0) {
            setSelectModul(0);
            setColor(mesh.children[11], "#f3a0b4");
          } else if (model1Click.length > 0) {
            setSelectModul(1);
            setColor(mesh.children[10], "#f3a0b4");
          } else if (model2Click.length > 0) {
            setSelectModul(2);
            setColor(mesh.children[13], "#f3a0b4");
          } else if (model3Click.length > 0) {
            setSelectModul(3);
            setColor(mesh.children[9], "#f3a0b4");
          }
        }
      });

      setOnLoading((prev) => prev + 1);
      setTimeout(() => {
        setOnLoading("none");
      }, 2000);
    });
  }, []);

  // ! rendering
  useEffect(() => {
    if (useRefCon.current) {
      useRefCon.current.appendChild(render.domElement);
    }
    animate();
  }, [useRefCon]);

  return (
    <div>
      <div className="model" ref={useRefCon}></div>
    </div>
  );
}

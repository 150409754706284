import { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import {
  BloomPass,
  FilmPass,
  GLTFLoader,
  OutputPass,
  RectAreaLightHelper,
  RenderPass,
} from "three/examples/jsm/Addons.js";
import { OrbitControls } from "three/examples/jsm/Addons.js";
import { setColor, setImage } from "../../../Functions/Functions";

export default function ModelTwo(props) {
  const useRefCon = useRef(null);
  let {
    setModelOne,
    modelOne,
    setSelectModul,
    setOnLoading,
    setSelectedImageButton,
  } = props;

  function animate() {
    requestAnimationFrame(animate);
    render.render(scene, camera);
  }

  // ! render
  const render = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    powerPreference: "low-power",
    stencil: true,
  });
  render.outputColorSpace = THREE.SRGBColorSpace;

  render.toneMapping = THREE.ReinhardToneMapping;
  render.toneMappingExposure = 1.5;
  render.setSize(window.innerWidth, window.innerHeight);

  // ! Scene
  const scene = new THREE.Scene();
  // ! Camera
  const camera = new THREE.PerspectiveCamera(
    45,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );

  camera.position.x = 5;
  camera.position.z = 8;
  camera.position.y = 3; //blandlik

  // ! Controls
  const controls = new OrbitControls(camera, render.domElement);
  controls.target = new THREE.Vector3(2, 2, 0);
  controls.update();

  // ! Lightings
  const ambientLight = new THREE.AmbientLight(0xffffff, 3);
  scene.add(ambientLight);

  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(0, 10, 0);
  scene.add(directionalLight);

  // ! Edit texture
  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load("/model/modelTwo/modelTwo.glb", (gltf) => {
      const mesh = gltf.scene;
      scene.add(mesh);

      let modelNumber1 = mesh.children[24]; //Stol usti
      let modelNumber2 = mesh.children[25]; //stol tagi
      let modelNumber3 = mesh.children[26]; //shkaf printer
      let modelNumber4 = mesh.children[23]; //shkaf
      setModelOne([modelNumber1, modelNumber2, modelNumber3, modelNumber4]);

      const mouse = new THREE.Vector2();
      const raycaster2 = new THREE.Raycaster();
      document.body.addEventListener("click", (e) => {
        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
        raycaster2.setFromCamera(mouse, camera);
        const model0Click = raycaster2.intersectObject(mesh.children[11]); //Stol usti 9
        const model1Click = raycaster2.intersectObject(mesh.children[9]); //stol tagi 10
        const model2Click = raycaster2.intersectObject(mesh.children[10]); //shkaf printer 11
        const model3Click = raycaster2.intersectObject(mesh.children[8]); //shkaf 12

        if (
          model0Click.length > 0 ||
          model1Click.length > 0 ||
          model2Click.length > 0 ||
          model3Click.length > 0
        ) {
          setColor(mesh.children[11], "white");
          setColor(mesh.children[9], "white");
          setColor(mesh.children[10], "white");
          setColor(mesh.children[8], "white");
          if (model0Click.length > 0) {
            setSelectModul(0);
            setSelectedImageButton("");
            setColor(mesh.children[11], "#f3a0b4");
          } else if (model1Click.length > 0) {
            setSelectModul(1);
            setSelectedImageButton("");
            setColor(mesh.children[9], "#f3a0b4");
          } else if (model2Click.length > 0) {
            setSelectModul(2);
            setSelectedImageButton("");
            setColor(mesh.children[10], "#f3a0b4");
          } else if (model3Click.length > 0) {
            setSelectModul(3);
            setSelectedImageButton("");
            setColor(mesh.children[8], "#f3a0b4");
          }
        }
      });
      setOnLoading((prev) => prev + 1);
      setTimeout(() => {
        setOnLoading("none");
      }, 2000);
    });
  }, []);

  // ! rendering
  useEffect(() => {
    if (useRefCon.current) {
      useRefCon.current.appendChild(render.domElement);
    }
    animate();
  }, [useRefCon]);

  return (
    <div>
      <div className="model" ref={useRefCon}></div>
    </div>
  );
}

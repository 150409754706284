import "./LoadingPage.css";

export default function LoadingPage(props) {
  let { onLoading } = props;
  return (
    <div className={`loading_animation ${onLoading > 0 && "hide"}`}>
      <div id="loader-wrapper">
        <div id="loader"></div>

        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
      </div>
    </div>
  );
}

import SelectCompanyMenu from "../../Functions/SelectCompanyMenu/SelectCompanyMenu";
import SelectDatesMenu from "../../Functions/SelectDatesMenu/SelectDatesMenu";
import SelectTypesMenu from "../../Functions/SelectTypesMenu/SelectTypesMenu";
import "./RightPonel.css";

export default function RightPonel(props) {
  let {
    modelOne,
    selectModul,
    selectedImageButton,
    setSelectedImageButton,
    allData,
    companys,
    setCompanyes,
    texturType,
    setTexturType,
    allDataLoading,
    currentSelectPageRight,
    setCurrentSelectPageRight,
    openRightMenu,
    setOpenRightMenu,
    setSelectCompany,
    setSelectType,
    products,
  } = props;

  function backButtonFunc() {
    if (currentSelectPageRight == "company") {
      setCurrentSelectPageRight("type");
    } else if (currentSelectPageRight == "data") {
      setCurrentSelectPageRight("company");
    }
  }
  function returnPanel() {
    if (currentSelectPageRight == "type") {
      return (
        <div className="box_textur_type2">
          <span className="rightPanelMainText">Tovar turini tanlang</span>
          <div>
            {texturType.map((a, i) => {
              return (
                <SelectTypesMenu
                  setTexturType={setTexturType}
                  setCurrentSelectPageRight={setCurrentSelectPageRight}
                  setSelectType={setSelectType}
                  Cid={a.Cid}
                  key={i}
                >
                  {a.Cname}
                </SelectTypesMenu>
              );
            })}
          </div>
        </div>
      );
    } else if (currentSelectPageRight == "company") {
      return (
        <div className="box_textur_company">
          <span className="rightPanelMainText">Firma nomini tanlang! </span>
          <div>
            {companys.map((a, i) => {
              return (
                <SelectCompanyMenu
                  setCurrentSelectPageRight={setCurrentSelectPageRight}
                  setSelectCompany={setSelectCompany}
                  Cid={a.id}
                  key={i}
                >
                  {a.name}
                </SelectCompanyMenu>
              );
            })}
          </div>
        </div>
      );
    } else if (currentSelectPageRight == "data") {
      return (
        <div className="box_textur_product">
          <span className="rightPanelMainText">
            Ko'rinish uslubini tanlang:
          </span>
          <div>
            {products.map((a, i) => {
              return (
                <SelectDatesMenu
                  modelOne={modelOne}
                  selectModul={selectModul}
                  // selectedImageButton={selectedImageButton}
                  // setSelectedImageButton={setSelectedImageButton}
                  key={i}
                >
                  {a}
                </SelectDatesMenu>
              );
            })}
          </div>
        </div>
      );
    }
  }
  return (
    <div className={`RightPonel ${openRightMenu && "loaded"}`}>
      {currentSelectPageRight != "type" && (
        <div className="backButton">
          <span onClick={() => backButtonFunc()}>Orqaga {selectModul}</span>
        </div>
      )}
      {returnPanel()}
    </div>
  );
}
